@import url(https://ui.dev/font);
:root {
  --black: #000;
  --white: #fff;
  --yellow: #f4f73e;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  font-family: proxima-nova, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 18px;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: #000;
  background: var(--black);
  color: #fff;
  color: var(--white);
}

a {
  color: #f4f73e;
  color: var(--yellow);
  text-decoration: none;
}

li {
  list-style-type: none;
}

.yellow-border {
  padding: 0;
  margin: 0;
  background: #f4f73e;
  background: var(--yellow);
  height: 5px;
  width: 100%;
}

.container {
  position: relative;
  max-width: 1000px;
  min-width: 280px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 50px;
}

.large-header {
  font-size: 105px;
  text-align: center;
  font-weight: 900;
}

.medium-header {
  font-size: 55px;
  text-align: center;
  margin: 20px 0;
  font-weight: 900;
}

.header {
  margin: 20px 0;
  font-weight: 300;
  font-size: 35px;
  font-weight: 900;
}

.center {
  display: block;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.row {
  display: flex;
  justify-content: space-around;
}

.home-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.navbar {
  font-size: 16px;
  text-transform: uppercase;
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.nav-links a {
  padding: 0 10px;
}

.two-column {
  display: flex;
}

.panel {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

.avatar {
  width: 200px;
  border-radius: 100px;
}

.info-list {
  margin-bottom: 30px;
  padding: 0;
}

.info-list li {
  font-size: 33px;
  margin: 20px 0;
  font-weight: 700;
}

.info-list div {
  font-size: 24px;
  font-weight: 300;
}

.sidebar-list {
  display: flex;
  padding: 10px;
  flex-direction: column;
  padding: 0;
}

.sidebar-list a {
  line-height: 25px;
}

.sidebar-instruction {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  font-weight: 900;
  font-size: 22px;
}

.articles {
  margin: 0;
  padding: 0;
}

.articles li {
  font-size: 28px;
  margin-bottom: 30px;
  cursor: pointer;
}

.article {
  margin: 20px;
  padding: 20px;
}

.article p {
  line-height: 35px;
  font-size: 20px;
}

.article-title {
  margin: 0;
  font-weight: 300;
}

.article-date {
  font-size: 14px;
  padding: 0;
}

.btn-main {
  max-width: 240px;
  min-width: 150px;
  padding: 10px;
  border: 2px solid #000;
  border: 2px solid var(--black);
  color: #000;
  color: var(--black);
  text-align: center;
  background: #f4f73e;
  background: var(--yellow);
  font-weight: 900;
  font-size: 15px;
}

.btn-main:hover {
  cursor: pointer;
  background-image: linear-gradient(rgba(0, 0, 0, 0.075) 0 0);
}

.championships {
  display: flex;
  margin: 0;
  padding: 0;
}

.championships li {
  margin: 0 10px;
}

@media (max-width: 680px) {
  .container {
    padding: 8px;
  }

  .large-header {
    font-size: 60px;
  }

  .medium-header {
    font-size: 40px;
  }

  .header {
    font-size: 25px;
    margin: 10px 0;
  }

  .avatar {
    width: 125px;
    border-radius: 62.5px;
  }

  .home-grid {
    flex-direction: column;
    align-items: center;
  }

  .info-list li {
    font-size: 20px;
  }

  .info-list div {
    font-size: 16px;
  }
  .championships li {
    font-size: 12px;
  }

  .sidebar-list {
    font-size: 12px;
  }

  .article {
    padding: 5px;
    margin: 5px;
  }

  .article-title {
    font-size: 16px;
  }
}

.loading {
  height: 0;
  width: 0;
  padding: 7px;
  border: 2px solid #f4f73e;
  border: 2px solid var(--yellow);
  border-right-color: #000;
  border-right-color: var(--black);
  border-radius: 22px;
  -webkit-animation: rotate 1s infinite linear;
          animation: rotate 1s infinite linear;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
